<template>
  <div class="container-fluid my-5">
    <div class="row">
      <div class="col-md-5">
        <div class="sticky">
          <div class="card">
            <div class="card-body">
              <ValidationObserver ref="form" v-slot="{ handleSubmit }">
                <b-form id="search-form" @submit.prevent="handleSubmit(onSubmit)">
                  <b-form-group>
                    <ValidationProvider vid="date_range" v-slot="{ errors }">
                      <HotelDatepicker
                        :alwaysVisible="true"
                        :showSingleMonth="true"
                        format="DD-MM-YYYY"
                        :disabledDates="blockedDates"
                        :halfDay="false"
                        @check-in-changed="updateCheckin"
                        @check-out-changed="updateCheckout"
                        :periodDates="periodDates"
                        :showPrice="true"
                        priceSymbol="€"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </ValidationProvider>
                  </b-form-group>

                  <b-form-group label="Guests" label-for="guests">
                    <Guest id="guests" @changeGuests="setGuests" />
                  </b-form-group>

                  <div class="mb-5">
                    <button type="submit" class="btn btn-block btn-success">Search</button>
                  </div>
                </b-form>
              </ValidationObserver>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-7">
        <h5 class="text-danger mb-4" v-if="!available">
          No Accommodation available in this period. Please try different dates
        </h5>
        <div class="card mb-4" v-for="lodging in lodgings" :key="lodging.id">
          <div class="card-body">
            <div class="row">
              <div class="col-md-4 mt-3">
                <div class="card-image">
                  <img :src="lodging.attributes.thumbnail" class="img-fluid img-height mx-auto d-block w-100" />
                </div>
              </div>

              <div class="col-md-5 mt-3">
                <ul class="list-unstyled list-desc">
                  <strong>
                    <h5>
                      {{ lodging.attributes.name }}
                    </h5>
                  </strong>

                  <p class="text-dark capitalize">
                    {{ lodging.attributes.country.name }} - {{ lodging.attributes.region.name }}
                  </p>

                  <li>
                    <p class="mt-3 font-size-12">
                      {{ lodging.attributes.description }}
                    </p>
                  </li>

                  <span
                    v-for="highlight in lodging.attributes.highlights"
                    :key="highlight"
                    class="badge badge-light mr-2 mt-2 badge-pill font-size-12"
                  >
                    {{ highlight }}
                  </span>
                </ul>
              </div>

              <div class="col-md-3 mt-3">
                <div class="text-right">
                  <h5>€{{ calculatePrice(lodging) }}</h5>
                  <router-link
                    tag="a"
                    :to="{ path: `lodgings/${lodging.id}/booking?checkin=${checkin}&checkout=${checkout}` }"
                    class="text-dark"
                  >
                    <b-button class="mt-2" variant="success">View Details</b-button>
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import appConfig from "@/app.config";
import { getBookings, getLodgings } from "@/store/api";
import moment from "moment";
import HotelDatepicker from "vue-hotel-datepicker";
import "vue-hotel-datepicker/dist/vueHotelDatepicker.css";
import Guest from "../components/guests";

export default {
  page: {
    title: "Lodgings",
    meta: [{ name: "description", content: appConfig.description }],
  },

  components: {
    HotelDatepicker,
    Guest,
  },

  data() {
    return {
      availabilities: [],
      available: true,
      dates: "",
      checkin: "",
      checkout: "",
      adults: 1,
      children: 0,
      lodgings: [],
    };
  },

  created() {
    getBookings(moment().format("YYYY-MM-DD")).then((response) => {
      this.availabilities = response.availabilities;
    });
  },

  computed: {
    blockedDates() {
      return this.availabilities.filter((price) => price.blockage == true).map((price) => price.date);
    },

    periodDates() {
      return this.availabilities.map((price) => {
        return {
          startAt: price.date,
          endAt: moment(price.date)
            .add(1, "days")
            .format("YYYY-MM-DD"),
          price: parseFloat(price.amount),
          periodType: "nightly",
          minimumDuration: 1,
        };
      });
    },
  },

  methods: {
    disabledBeforeStartDate(date) {
      return date <= this.checkin;
    },

    disabledAfterEndDate(date) {
      if (!this.checkout) return date < Date.now();
      return date < Date.now() || date >= this.checkout;
    },

    updateCheckin(date) {
      this.checkin = date;
    },

    updateCheckout(date) {
      this.checkout = date;
    },

    setGuests(adults, children) {
      this.adults = adults;
      this.children = children;
    },

    calculatePrice(lodging) {
      let amount = 0;
      for (let price of lodging.attributes.prices.data) {
        amount += parseFloat(price.attributes.amount);
      }
      amount +=
        lodging.attributes.base_price *
        (moment(this.checkout).diff(this.checkin, "days") - lodging.attributes.prices.data.length);
      return amount.toFixed(2);
    },

    onSubmit() {
      if (!this.checkin || !this.checkout) {
        this.$refs.form.setErrors({ date_range: "Date range not selected" });
      } else {
        getLodgings({
          "q[allowed_guests]": this.adults + this.children,
          "q[available]": [moment(this.checkin).format("YYYY-MM-DD"), moment(this.checkout).format("YYYY-MM-DD")],
        }).then((response) => {
          this.lodgings = response;
          if (this.lodgings.length) {
            this.available = true;
          } else {
            this.available = false;
          }
        });
      }
    },
  },
};
</script>

<style scoped>
.sticky {
  position: sticky;
  top: 0;
}
.img-height {
  height: 200px;
}
</style>
