<template>
  <b-dropdown
    class="select-dropdown d-lg-block w-100 bg-white"
    toggle-class="btn-outline-light select-btn w-100"
    menu-class="dropdown-menu-lg p-0 col-sm-12 w-100"
    variant="white"
  >
    <template v-slot:button-content>
      {{ guestTitle }}
      <i class="mdi mdi-chevron-down ml-1 float-right"></i>
    </template>

    <div class="p-3">
      <div class="dropdown-item" v-for="(guestType, index) in guestTypes" :key="index">
        <div class="row align-items-center">
          <div class="col-6">
            <div>
              <p class="text-dark mb-0">{{ guestType.name }}</p>
            </div>
          </div>

          <div class="col-6">
            <div class="input-group guest-input-group">
              <div class="input-group-prepend">
                <button class="btn btn-outline-light minus-btn" type="button" @click="decrement(guestType)">
                  <i class="mdi mdi-minus"></i>
                </button>
              </div>

              <input type="text" class="form-control" v-model="guestType.guestCount" placeholder />

              <div class="input-group-append">
                <button class="btn btn-outline-light plus-btn" type="button" @click="increment(guestType)">
                  <i class="mdi mdi-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-dropdown>
</template>

<script>
export default {
  data() {
    return {
      title: "Select Guest",
      guestTypes: [
        {
          name: "Adults",
          guestCount: 1,
        },
        {
          name: "Children",
          guestCount: 0,
        },
      ],
      singularGuestTypes: ["Adult", "Child"],
    };
  },

  computed: {
    guestTitle() {
      let selectedGuests = [];
      this.guestTypes.forEach((guestType, index) => {
        if (guestType.guestCount === 1)
          selectedGuests.push(`${guestType.guestCount} ${this.singularGuestTypes[index]}`);
        else if (guestType.guestCount > 1) selectedGuests.push(`${guestType.guestCount} ${guestType.name}`);
      });
      if (selectedGuests.length > 0) return selectedGuests.join(" - ");
      return this.title;
    },
  },

  methods: {
    increment(guestType) {
      guestType.guestCount++;
    },
    decrement(guestType) {
      if (guestType.guestCount > 0) guestType.guestCount--;
    },
  },

  watch: {
    guestTypes: {
      deep: true,
      // eslint-disable-next-line no-unused-vars
      handler: function(newGuestTypes, oldGuestTypes) {
        this.$emit("changeGuests", newGuestTypes[0].guestCount, newGuestTypes[1].guestCount);
      },
    },
  },
};
</script>
